import { loadingActions } from 'modules/loading';
import { OTP_TYPES } from 'constants/signup';
import { getFCMToken, getStoreData, saveStoreData } from 'utils/events';
import { LOCAL_STORAGE_PREFIX } from 'constants/global';
import { STORAGE_PUSH_NOTIFICATIONS } from 'constants/local';
import { sessionActions } from '.';
import SessionApi from './repository';

export function validateSession(destroy = false) {
  return (dispatch) => {
    dispatch(loadingActions.show());
    dispatch(sessionActions.init());
    return SessionApi.session()
      .then((user) => {
        if (destroy) {
          const callback = () => {
            dispatch(sessionActions.clear());
            dispatch(loadingActions.hide());
            return 'session_destroyed';
          };
          return SessionApi.logout()
            .then(callback)
            .catch(callback);
        }
        dispatch(sessionActions.success(user));
        dispatch(loadingActions.hide());
        return true;
      })
      .catch(() => {
        dispatch(sessionActions.error());
        dispatch(loadingActions.hide());
        return false;
      });
  };
}

export function generateSessionToken(user, callback) {
  // eslint-disable-next-line no-use-before-define
  return getSessionToken()
    .then((response) => {
      const data = {
        token: response?.value,
        expire: response?.expire,
        userId: user?.id,
      };
      saveStoreData('MLS-Token', data);
      callback(true);
      return true;
    })
    .catch(() => {
      callback(false);
      return false;
    });
}

const renewSessionToken = (user, expire, force = false) => {
  const expireDate = Number(expire) * 1000;
  const actualDate = new Date().getTime();
  const diff = expireDate - actualDate;

  const daysToExpire = Math.floor(diff / (1000 * 60 * 60 * 24));

  if (typeof window.sentryBiometricLog === 'undefined') {
    window.sentryBiometricLog = [];
  }

  window.sentryBiometricLog.push({
    message: 'renewSessionTk: expire',
    data: {
      expire, expireDate, diff, daysToExpire, force,
    },
  });

  if (daysToExpire <= 10 || force) {
    window.sentryBiometricLog.push({
      message: 'renewSessionTk: generateSessionTk',
    });
    return generateSessionToken(user, (success) => {
      window.sentryBiometricLog.push({
        message: 'renewSessionTk: response',
        data: {
          success,
        },
      });
      return success;
    });
  }

  return new Promise((resolve) => {
    resolve(null);
  });
};

export const savePushNotificationToken = (userId = null, manual = false) => {
  try {
    const lsKey = `${LOCAL_STORAGE_PREFIX}${STORAGE_PUSH_NOTIFICATIONS}`;
    let currentSubscription = window.localStorage.getItem(lsKey);
    currentSubscription = currentSubscription ? JSON.parse(currentSubscription) : {};
    if (currentSubscription.enabled === false && !manual) {
      return;
    }
    getFCMToken()
      .then((fcm) => {
        if (fcm?.token) {
          if (currentSubscription?.token && userId && !currentSubscription?.userId) {
            SessionApi.deletePublicNotificationsToken({
              token: currentSubscription.token,
              device_id: fcm.device,
            });
          }
          const data = { token: fcm.token, device_id: fcm.device };
          if (currentSubscription.userId !== userId || currentSubscription.token !== fcm.token) {
            if (userId) {
              SessionApi.setNotificationsToken(data);
            } else {
              SessionApi.setPublicNotificationsToken(data);
            }
            window.localStorage.setItem(lsKey, JSON.stringify({
              token: fcm.token,
              userId,
              enabled: true,
            }));
          }
        }
      });
  } catch (error) {
    console.error('savePushNotificationToken: error', error);
  }
};

export const deletePublicNotificationsToken = (userId = null) => {
  try {
    const lsKey = `${LOCAL_STORAGE_PREFIX}${STORAGE_PUSH_NOTIFICATIONS}`;
    let currentSubscription = window.localStorage.getItem(lsKey);
    currentSubscription = currentSubscription ? JSON.parse(currentSubscription) : {};
    getFCMToken()
      .then((fcm) => {
        const data = {
          token: fcm.token || currentSubscription.token || '',
          device_id: fcm.device,
        };
        if (!userId) {
          SessionApi.deletePublicNotificationsToken(data);
        } else {
          SessionApi.deleteNotificationsToken(data);
        }
        window.localStorage.setItem(lsKey, JSON.stringify({ enabled: false }));
      });
  } catch (error) {
    console.error('savePushNotificationToken: error', error);
  }
};

export const biometricLoginAction = (data, expire) => async (dispatch) => {
  dispatch(sessionActions.clearError());
  dispatch(loadingActions.show());
  if (typeof window.sentryBiometricLog === 'undefined') {
    window.sentryBiometricLog = [];
  }

  return SessionApi.signinWithToken(data)
    .then((user) => {
      window.sentryBiometricLog.push({
        message: 'signinWith: then',
      });
      savePushNotificationToken(user.id);
      renewSessionToken(user, expire);

      dispatch(sessionActions.success(user));
      dispatch(loadingActions.hide());

      return user;
    })
    .catch((err) => {
      window.sentryBiometricLog.push({
        message: 'signinWith catch',
        data: err,
      });
      dispatch(loadingActions.hide());
      return false;
    });
};

export const onLogin = (data) => async (dispatch) => {
  dispatch(sessionActions.clearError());
  dispatch(loadingActions.show());
  window.sentryBiometricLog = [];

  return SessionApi.login(data)
    .then((user) => {
      savePushNotificationToken(user.id);

      if (window.onAndroidApp() || window.onIOSApp()) {
        getStoreData('MLS-Token').then((item) => {
          if (item?.expire) {
            renewSessionToken(user, item.expire, item?.userId !== user.id);
          }
        });
      }

      dispatch(sessionActions.success(user));
      dispatch(loadingActions.hide());

      return user;
    })
    .catch(() => {
      dispatch(sessionActions.error('unknownUserOrPassword'));
      dispatch(loadingActions.hide());
      return false;
    });
};

export const onSignup = (form) => {
  return SessionApi.signup({
    sendOtp: 'EMAIL_VERIFICATION',
    email: form.email,
    name: form.name,
    firstname: form.firstname,
    lastname: form.lastname,
    phone: form.phone,
  }).then(() => {
    return true;
  }).catch((error) => {
    throw error;
  });
};

export const onLogout = () => async (dispatch) => {
  dispatch(sessionActions.clearError());
  dispatch(loadingActions.show());

  return SessionApi.logout()
    .then(() => {
      dispatch(sessionActions.clear());
      return true;
    })
    .catch(() => {
      dispatch(sessionActions.clear());
      dispatch(loadingActions.hide());
      return false;
    });
};

export async function recoverPassword(form) {
  try {
    const response = await SessionApi.recoverPassword(form);
    return response || true;
  } catch (error) {
    return false;
  }
}

export function resetPassword({ token, password }) {
  return SessionApi.setPassword({ token, password });
}

export async function editPassword(oldPassword, newPassword) {
  try {
    await SessionApi.editPassword({ oldPassword, newPassword });
    return true;
  } catch (error) {
    return error;
  }
}

export function getOtpConfiguration() {
  return SessionApi.getOtpConfiguration();
}

export function getSessionToken() {
  return SessionApi.getSessionToken();
}

export async function createPassword(token, password) {
  try {
    const response = await SessionApi.createPassword(token, password);
    return response || true;
  } catch (error) {
    return false;
  }
}

export async function getUserOtp(id, type) {
  try {
    const response = await SessionApi.getUserOtp(id, type === OTP_TYPES.EMAIL ? 'EMAIL' : 'SMS');
    return response;
  } catch (error) {
    return false;
  }
}

export async function checkOtp(id, otp) {
  return SessionApi.checkOtp(id, otp);
}

export function checkLinkOtp(id, otp) {
  return SessionApi.checkLinkOtp(id, otp);
}

export function unlinkSAP() {
  return SessionApi.unlinkSAP();
}

export async function createPublicToken(token) {
  return SessionApi.createPublicToken(token);
}

export const getUserAddress = () => async (dispatch) => {
  dispatch(loadingActions.show());
  return SessionApi.getUserAddress()
    .then((address) => {
      dispatch(loadingActions.hide());
      dispatch(sessionActions.getUserAddressSuccess(address));
      return address;
    })
    .catch(() => {
      dispatch(loadingActions.hide());
      return false;
    });
};

export const cleanError = () => (dispatch) => {
  dispatch(sessionActions.clearError());
};

export const editUser = (data) => async (dispatch) => {
  dispatch(loadingActions.show());
  return SessionApi.editUser(data)
    .then(() => {
      dispatch(validateSession());
      return true;
    }).finally(() => {
      dispatch(loadingActions.hide());
    });
};

export const changeEmailPhone = (data) => async (dispatch) => {
  dispatch(loadingActions.show());
  return SessionApi.changeEmailPhone(data)
    .then(() => {
      return true;
    }).finally(() => {
      dispatch(loadingActions.hide());
    });
};

export const confirmChangeEmailPhone = (data) => async (dispatch) => {
  dispatch(loadingActions.show());
  return SessionApi.confirmChangeEmailPhone(data)
    .then(async () => {
      await dispatch(validateSession());
      return true;
    })
    .finally(() => {
      dispatch(loadingActions.hide());
    });
};

export const editAvatar = (image) => async (dispatch) => {
  dispatch(loadingActions.show());
  return SessionApi.editAvatar(image)
    .then(() => {
      dispatch(loadingActions.hide());
      dispatch(validateSession());
      return true;
    })
    .catch(() => {
      dispatch(loadingActions.hide());
      return false;
    });
};

export const getGestopagoUrl = () => async (dispatch) => {
  dispatch(loadingActions.show());
  return SessionApi.getGestopagoUrl()
    .then((url) => {
      return url;
    })
    .catch(() => {
      return false;
    }).finally(() => {
      dispatch(loadingActions.hide());
    });
};
