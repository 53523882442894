import useLiterals from 'utils/hooks/useLiterals';
import LogoImg from 'assets/logos/logo.png';
import LogoAndroid from 'assets/images/play-store.png';
import LogoApple from 'assets/images/app-store.png';
import Button from 'components/UI/Button';
import { APP_STORE_URL } from 'constants/global';
import './styles.scss';

const DownloadApp = () => {
  const literals = useLiterals('downloadApp');
  return (
    <div className='download-app'>
      <img className='logo' src={LogoImg} alt='Platform logo' />
      <h1 className='mb-5 mt-5'>{literals.title}</h1>
      <p className='mb-3'><b>{literals.available}</b></p>
      <Button to={APP_STORE_URL.ANDROID} className='mb-4' type='secondary'>
        <img src={LogoAndroid} alt='Platform logo' />
        Play Store
      </Button>
      <Button to={APP_STORE_URL.IOS} type='secondary'>
        <img src={LogoApple} alt='Platform logo' />
        App Store
      </Button>
    </div>
  );
};

export default DownloadApp;
