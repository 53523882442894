import { useEffect, useState } from 'react';
import Layout from 'components/Layout';
import { getGestopagoUrl } from 'modules/session/actions';
import { useDispatch } from 'react-redux';
import ErrorModal from 'components/FeedbackModals/ErrorModal';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from 'routes';
import useLiterals from 'utils/hooks/useLiterals';

const PortalGestopago = () => {
  const literals = useLiterals('gestopago');
  const [url, setUrl] = useState('');
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getGestopagoUrl()).then((res) => {
      if (res) {
        setUrl(res.replace('gestopago.portalventas.net', 'montepio.portalventas.net'));
      } else {
        setError(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      className='gestopago-portal'
      headerMenu={!!url}
      bottomMenu={!url}
      rightMenu={false}
      onBack={() => navigate(ROUTE_PATH.HOME, { replace: true })}
    >
      {url && (
        <iframe
          className='embed-page with-menu'
          src={url}
          title='Gestopago'
        />
      )}
      {error && (
        <ErrorModal
          description={literals.error}
          onClose={() => navigate(ROUTE_PATH.HOME)}
        />
      )}
    </Layout>
  );
};

export default PortalGestopago;
