/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  createReference: '/payments/paycash/references',
  createMultipleReference: '/payments/paycash/references/batch',
  createReferencePuntored: '/payments/puntored/references',
  createMultipleReferencePuntored: '/payments/puntored/references/batch',
  verify: '/payments/verify',
  paycashPDF: (externalOrder) => `/payments/paycash/references/${externalOrder}/pdf`,
  reference: (reference) => `/payments/paycash/references/${reference}`,
  referencePuntored: (reference) => `/payments/puntored/references/${reference}`,
};

export default {
  async createReference(data) {
    const response = await ApiFetchInstance.post(URLS.createReference, data);
    return response;
  },
  async createMultipleReference(data) {
    const response = await ApiFetchInstance.post(URLS.createMultipleReference, data);
    return response;
  },
  async createReferencePuntored(data) {
    const response = await ApiFetchInstance.post(URLS.createReferencePuntored, data);
    return response;
  },
  async createMultipleReferencePuntored(data) {
    const response = await ApiFetchInstance.post(URLS.createMultipleReferencePuntored, data);
    return response;
  },
  async getPaycashPDF(externalOrder) {
    const response = await ApiFetchInstance.get(URLS.paycashPDF(externalOrder), { responseType: 'blob' });
    return response;
  },
  async deleteReference(referenceId) {
    const response = await ApiFetchInstance.delete(URLS.reference(referenceId));
    return response;
  },
  async deleteReferencePuntored(referenceId) {
    const response = await ApiFetchInstance.delete(URLS.referencePuntored(referenceId));
    return response;
  },
  async verifyHour() {
    const response = await ApiFetchInstance.get(URLS.verify);
    return response;
  },
};
