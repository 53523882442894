/* eslint-disable no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GA_EVENTS } from 'constants/gaEvents';
import { sendGaEvent } from 'utils/sendGaEvent';
import useLiterals from 'utils/hooks/useLiterals';
import { loadingActions } from 'modules/loading';
import Layout from 'components/Layout';
import StoresMap from 'components/StoresMap';
import SuccessModal from 'components/FeedbackModals/SuccessModal';
import Button from 'components/UI/Button';
import Modal from 'components/Modal';
import { ROUTE_PATH } from 'routes';
import { cleanStore } from 'modules/stores/actions';
import { EMAIL_REGEX } from 'constants/global';
import './styles.scss';

const Appointment = () => {
  const literals = useLiterals('appointment');
  const literalsStores = useLiterals('stores');
  const { user } = useSelector((state) => state.session);
  const { store } = useSelector((state) => state.stores);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appointmentContainerRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showOfficesModal, setShowOfficesModal] = useState(false);
  const [sendedForm, setSendedForm] = useState({});
  const [loaded, setLoaded] = useState(false);
  const { ZOHO_SCRIPTS = {} } = window;

  const initializeForm = () => {
    const allInputs = document.querySelectorAll('input');
    let officeInput = null;
    let clientInput = null;
    allInputs.forEach((input) => {
      if (input.placeholder === 'Sucursal asignada' || input.id === 'LEADCF11') {
        officeInput = input;
      }
      if (input.placeholder === 'Número de Cliente' || input.id === 'LEADCF47') {
        clientInput = input;
      }
    });

    if (officeInput) {
      officeInput.value = store?.name || store?.address || '';
    }
    if (clientInput && user?.clientId) {
      clientInput.value = user.clientId;
    }
  };

  useEffect(() => {
    if (loaded) {
      initializeForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, store, user]);

  useEffect(() => {
    dispatch(loadingActions.show());
    sendGaEvent(GA_EVENTS.APPOINTMENT);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = ZOHO_SCRIPTS[urlParams.get('type')] || ZOHO_SCRIPTS.APPOINTMENT;
    script.async = true;

    script.onload = () => {
      // Espera a que el contenido sea creado por el script de Zoho CRM.
      setTimeout(() => {
        const zohoContent = document.querySelector('.crmWebToEntityForm');
        if (appointmentContainerRef.current && zohoContent) {
          appointmentContainerRef.current.appendChild(zohoContent);
        }

        const formsubmitElements = document.getElementsByClassName('formsubmit');
        for (let i = 0; i < formsubmitElements.length; i += 1) {
          const element = formsubmitElements[i];

          // Eliminar todas las clases del elemento
          element.className = 'formsubmit';

          element.classList.add('btn');
          element.classList.add('btn-primary');
          element.classList.add('mb-4');
          element.classList.add('btn-size-md');
          element.classList.add('font-size-md');

          element.value = literals.scripts[element?.value?.toLowerCase()] || element.value;
        }

        const secondaryButtons = document.getElementsByClassName('zcwf_button');
        for (let i = 0; i < secondaryButtons.length; i += 1) {
          const element = secondaryButtons[i];

          // Eliminar todas las clases del elemento
          element.className = '';

          element.classList.add('btn');
          element.classList.add('btn-secondary');
          element.classList.add('mb-4');
          element.classList.add('btn-size-md');
          element.classList.add('font-size-md');

          element.value = literals.scripts[element?.value?.toLowerCase()] || element.value;
        }

        const inputs = [
          ...document.querySelectorAll('input'),
          ...document.querySelectorAll('select'),
        ];

        for (let i = 0; i < inputs.length; i += 1) {
          const element = inputs[i];
          if (element.id && element.id !== 'formsubmit') {
            element.className = 'form-control';
            const labels = document.querySelectorAll(`label[for="${element.id}"]`);
            if (element.type === 'select-one' && labels.length > 0 && element.options.length > 0) {
              element.options[0].textContent = labels[0].textContent;
            } else if (labels.length > 0 && element.getAttribute('ftype') !== 'date') {
              const label = labels[0];
              element.placeholder = literals.scripts[element.id] || label.textContent;
            }

            if (element.id === 'First_Name' || element.id === 'Last_Name') {
              element.addEventListener('input', (event) => {
                const inputValue = event.target.value;
                if (/[^a-zA-Z\s]/.test(inputValue)) {
                  // eslint-disable-next-line no-param-reassign
                  event.target.value = inputValue.replace(/[^a-zA-Z\s]/g, '');
                }
              });
            }
            if (element.id === 'Mobile') {
              element.maxLength = 10;
              element.addEventListener('input', (event) => {
                const inputValue = event.target.value;
                if (/\D/.test(inputValue)) {
                  // eslint-disable-next-line no-param-reassign
                  event.target.value = inputValue.replace(/\D/g, '');
                }
              });
            }
            if (element.placeholder === 'Sucursal asignada' || element.id === 'LEADCF11') {
              element.onclick = () => {
                setShowOfficesModal(true);
              };
            }
          }
        }

        const cols = [
          ...document.getElementsByClassName('zcwf_col_fld'),
          ...document.getElementsByClassName('zcwf_col_fld_slt'),
        ];
        for (let i = 0; i < cols.length; i += 1) {
          const element = cols[i];
          // Eliminar todas las clases del elemento
          element.className = 'd-flex gap-2';
        }

        const helpTexts = document.getElementsByClassName('zcwf_col_help');

        for (let i = 0; i < helpTexts.length; i += 1) {
          const element = helpTexts[i];
          if (element.textContent !== '') {
            element.className = 'btn-link font-size-sm help-link';
          }
        }

        const zohoForm = document.querySelector('.crmWebToEntityForm form');

        if (zohoForm) {
          const onsubmitAttribute = zohoForm.getAttribute('onsubmit');
          if (onsubmitAttribute) {
            const match = onsubmitAttribute.match(/checkMandatory\d+/);
            const checkMandatoryFunctionName = match ? match[0] : null;
            zohoForm.removeAttribute('onsubmit');

            zohoForm.addEventListener('submit', (event) => {
              if (checkMandatoryFunctionName) {
                const checkMandatoryFunction = window[checkMandatoryFunctionName];
                const email = document.getElementById('Email');
                let emailValid = true;
                if (email) {
                  // check if is a valid email
                  const emailValue = email.value;
                  if (!EMAIL_REGEX.test(emailValue)) {
                    emailValid = false;
                    // eslint-disable-next-line no-alert
                    alert('El correo electrónico no es válido');
                  }
                }
                const isFormValid = checkMandatoryFunction() && emailValid;
                if (isFormValid || isFormValid === undefined) {
                  handleFormSubmit(event);
                } else {
                  event.preventDefault();
                }
              } else {
                handleFormSubmit(event);
              }
            });
          }
        }

        const officesLink = document.querySelector('a[href="https://www.montepio.org.mx/sucursales-empeño"]');

        if (officesLink) {
          const span = document.createElement('span');
          span.textContent = literals.selectOffice;

          span.onclick = () => {
            // Aquí puedes añadir lo que quieras que ocurra cuando se haga clic en el span
            setShowOfficesModal(true);
          };
          officesLink.parentNode.insertBefore(span, officesLink);
          officesLink.parentNode.removeChild(officesLink);
        }

        const resetButton = document.querySelector('input[type=reset]');
        if (resetButton) {
          resetButton.addEventListener('click', () => {
            setTimeout(() => {
              initializeForm();
            }, 200);
          });
        }

        dispatch(loadingActions.hide());
        setLoaded(true);
      }, 1000); // El tiempo de espera depende de cuánto tarda Zoho CRM en cargar el contenido, podrías necesitar ajustar este valor.
    };

    try {
      const oldForm = document.getElementById('crmWebToEntityForm');
      if (oldForm) {
        oldForm.remove();
      }
      document.body.appendChild(script);
    } catch (error) {
      console.error('Error al cargar el script de Zoho CRM:', error);
    }

    return () => {
      const messagesLayers = document.querySelectorAll('.messages-layer');
      messagesLayers.forEach((layer) => {
        layer.remove(); // Elimina el elemento div con la clase "messages-layer"
      });
      const chatFormLayers = document.querySelectorAll('.chat-form');
      chatFormLayers.forEach((layer) => {
        layer.remove(); // Elimina el elemento div con la clase "messages-layer"
      });
      document.body.removeChild(script);
      const form = document.getElementById('crmWebToEntityForm');
      if (form) {
        form.remove();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFormSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);

    const office = formData.get('LEADCF11');
    const time = `${formData.get('LEADCF116hour')}:${formData.get('LEADCF116minute')} ${formData.get('LEADCF116ampm')}`;
    const date = formData.get('LEADCF116');

    setSendedForm({
      office,
      time,
      date,
    });

    fetch(event.target.action, {
      method: 'POST',
      body: formData,
    })
      .then(() => {
        setShowSuccessModal(true);
      })
      .catch((error) => {
        // Aquí puedes manejar cualquier error que ocurra durante el envío
        console.error('Error al enviar el formulario:', error);
      });
  }

  return (
    <Layout onBack>
      {showSuccessModal && (
        <SuccessModal
          title={literals.successTitle}
          description=''
          onClose={() => {
            setShowSuccessModal(false);
            navigate(ROUTE_PATH.HOME);
          }}
        >
          <div className='text-center p-3'>
            {store?.name && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.office}</div>
                <div className='text-gray mb-2'>{store?.name}</div>
              </>
            )}
            {store?.address && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.address}</div>
                <div className='text-gray mb-2'>{store?.address}</div>
              </>
            )}
            {store?.phone1 && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.common.telephone}</div>
                <div className='text-gray mb-2'>
                  <Link to={`tel:${store.phone1}`} target='_blank' rel='noreferrer'>
                    {store.phone1}
                  </Link>
                </div>
              </>
            )}
            {sendedForm?.date && (
              <>
                <div className='text-primary font-bold mb-1'>{literals.date}</div>
                <div className='text-gray mb-2'>{`${sendedForm?.date} ${sendedForm?.time}`}</div>
              </>
            )}
          </div>
        </SuccessModal>
      )}
      {showOfficesModal && (
        <Modal
          title={literals.selectOffice}
          onCloseModal={() => setShowOfficesModal(false)}
          showCloseButton
        >
          <StoresMap
            searcher={!store ? {
              title: literalsStores.search,
              placeholder: literalsStores.addressSearch,
            } : null}
            showNearests={!store}
          />
          {store && (
            <div className='mt-4'>
              <Button
                type='secondary'
                className='mt-3'
                text={literalsStores.selectOther}
                onClick={() => dispatch(cleanStore())}
              />
              <Button
                className='mt-3'
                type='primary'
                text={literals.common.confirm}
                onClick={() => setShowOfficesModal(false)}
              />
            </div>
          )}
        </Modal>
      )}
      <div className='appointment-container' ref={appointmentContainerRef} id='appointment-container'>
        <div />
      </div>
    </Layout>
  );
};

export default Appointment;
