import { ROUTE_BASE_PATH, ROUTE_PATH, ROUTE_TYPE_REDIRECT } from 'routes';
import Logout from './Logout';
import Error from './Error';
import Home from './Home';
import Login from './Auth/Login';
import Signup from './Auth/Signup';
import Onboarding from './Onboarding';
import Quoter from './Quoter';
import Services from './Services';
import RecoverPassword from './Auth/RecoverPassword';
import ResetPassword from './Auth/ResetPassword';
import Billing from './Billing';
import AutomaticQuoter from './AutomaticQuoter';
import Settings from './Settings';
import Stores from './Stores';
import Documents from './Documents';
import Profile from './Profile';
import Store from './Store';
import Payments from './Payments';
import PaymentGateway from './PaymentGateway';
import ExecutiveDriver from './ExecutiveDriver';
import Appointment from './Appointment';
import Chat from './Chat';
import Payment from './Payment';
import ContractPayment from './ContractPayment';
import NotFound from './NotFound';
import Finsus from './Finsus';
import Welcome from './Welcome';
import PaymentResponse from './PaymentResponse';
import Auctions from './Auctions';
import DownloadApp from './DownloadApp';
import SessionClosed from './SessionClosed';
import MultiplePayment from './MultiplePayment';
import MultiplePaymentResponse from './MultiplePaymentResponse';
import Maintenance from './Maintenance';
import EditProfile from './Profile/components/EditProfile';
import PaymentRedirect from './PaymentRedirect';
import UpdateApp from './UpdateApp';
import PortalGestopago from './PortalGestopago';

export const routes = [
  {
    type: ROUTE_TYPE_REDIRECT,
    from: ROUTE_BASE_PATH,
    to: ROUTE_PATH.ONBOARDING,
    exact: true,
  },
  {
    path: ROUTE_PATH.LOGIN,
    component: Login,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.RECOVER_PASSWORD,
    component: RecoverPassword,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.RESET_PASSWORD,
    component: ResetPassword,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.SIGNUP,
    component: Signup,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.FINSUS,
    component: Finsus,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.GESTOPAGO,
    component: PortalGestopago,
    exact: true,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.APPOINTMENT,
    component: Appointment,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.BBVA_RESPONSE,
    component: PaymentResponse,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.BBVA_RESPONSE_MULTIPLE,
    component: MultiplePaymentResponse,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.BBVA_REDIRECT,
    component: PaymentRedirect,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.HOME,
    component: Home,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.CONTRACT,
    component: Payment,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.SECTION,
    component: Payment,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.CONTRACT_PAYMENT,
    component: ContractPayment,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.SECTION_PAYMENT,
    component: ContractPayment,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.SETTINGS,
    component: Settings,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.ONBOARDING,
    component: Onboarding,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.WELCOME,
    component: Welcome,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.AUTOMATIC_QUOTER,
    component: AutomaticQuoter,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.QUOTER,
    component: Quoter,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.SERVICES,
    component: Services,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.STORES,
    component: Stores,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.PAYMENTS,
    component: Payments,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.RECEIPTS_HISTORY,
    component: Payments,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.PAYMENTS_MULTIPLE,
    component: MultiplePayment,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.PAYMENT_GATEWAY,
    component: PaymentGateway,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.DOCUMENTS,
    component: Documents,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.STORE,
    component: Store,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.BILLING,
    component: Billing,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.CHAT,
    component: Chat,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.PROFILE,
    component: Profile,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.PROFILE_EDIT,
    component: EditProfile,
    exact: false,
    ignoreSession: false,
    header: true,
  },
  {
    path: ROUTE_PATH.EXECUTIVE_DRIVER,
    component: ExecutiveDriver,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.AUCTIONS,
    component: Auctions,
    exact: false,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.ERROR,
    component: Error,
    exact: false,
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.LOGOUT,
    component: Logout,
    exact: false,
    ignoreSession: true,
  },
  {
    path: ROUTE_PATH.SESSION_CLOSED,
    component: SessionClosed,
    exact: false,
    ignoreSession: true,
  },
  {
    path: '*',
    component: NotFound,
    exact: false,
    ignoreSession: true,
  },
];

export const routesWeb = [
  {
    path: ROUTE_PATH.RESET_PASSWORD,
    component: ResetPassword,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.BBVA_REDIRECT,
    component: PaymentRedirect,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.BBVA_RESPONSE,
    component: PaymentResponse,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: ROUTE_PATH.BBVA_RESPONSE_MULTIPLE,
    component: MultiplePaymentResponse,
    exact: true,
    ignoreSession: true,
    header: true,
  },
  {
    path: '*',
    component: DownloadApp,
    exact: false,
    ignoreSession: true,
  },
];

export const routesMaintenance = [
  {
    path: '*',
    component: Maintenance,
    exact: false,
    ignoreSession: true,
  },
];

export const routesUpdateApp = [
  {
    path: '*',
    component: UpdateApp,
    exact: false,
    ignoreSession: true,
  },
];
