import { loadingActions } from 'modules/loading';
import { ZOHO_SCRIPT_TYPES } from 'constants/zohoScripts';
import { replacePlatformLanguageAction } from 'modules/i18n/actions';
import { rgbToHex } from 'utils/rgbToHex';
import { configurationAction } from '.';
import ConfigurationApi from './repository';

export const getGlobalConfiguration = () => async (dispatch) => {
  dispatch(loadingActions.show());

  return ConfigurationApi.getGlobalConfiguration()
    .then((response) => {
      const primaryColor = response.find((item) => item.name === 'primaryColor');
      const secondaryColor = response.find((item) => item.name === 'secondaryColor');
      if (primaryColor) {
        primaryColor.value = rgbToHex(primaryColor.value);
        document.documentElement.style.setProperty('--primary-color', primaryColor.value);
        document.documentElement.style.setProperty('--primary-color-light', `${primaryColor.value}85`);
      }
      if (secondaryColor) {
        secondaryColor.value = rgbToHex(secondaryColor.value);
        document.documentElement.style.setProperty('--secondary-color', secondaryColor.value);
      }

      const zoho = response.find((item) => item.name === 'zoho');
      if (zoho) {
        try {
          const zohoScripts = JSON.parse(zoho.value);
          window.ZOHO_SCRIPTS = {
            [ZOHO_SCRIPT_TYPES.APPOINTMENT]: zohoScripts.appointment || window.ZOHO_SCRIPTS[ZOHO_SCRIPT_TYPES.APPOINTMENT],
            [ZOHO_SCRIPT_TYPES.CARS]: zohoScripts.cars || window.ZOHO_SCRIPTS[ZOHO_SCRIPT_TYPES.CARS],
            [ZOHO_SCRIPT_TYPES.DIAMONDS]: zohoScripts.diamonds || window.ZOHO_SCRIPTS[ZOHO_SCRIPT_TYPES.DIAMONDS],
            [ZOHO_SCRIPT_TYPES.JEWELRY]: zohoScripts.jewelry || window.ZOHO_SCRIPTS[ZOHO_SCRIPT_TYPES.JEWELRY],
            [ZOHO_SCRIPT_TYPES.WATCHES]: zohoScripts.watches || window.ZOHO_SCRIPTS[ZOHO_SCRIPT_TYPES.WATCHES],
            [ZOHO_SCRIPT_TYPES.DRIVER]: zohoScripts.driver || window.ZOHO_SCRIPTS[ZOHO_SCRIPT_TYPES.DRIVER],
          };
        } catch (error) {
          console.error('error', error);
        }
      }

      let literals = response.find((item) => item.name === 'literals');
      if (literals?.value) {
        try {
          literals = JSON.parse(literals.value || '{}');
          dispatch(replacePlatformLanguageAction(literals || {}));
        } catch (error) {
          /**/
        }
      }

      dispatch(configurationAction.success(response));
      dispatch(loadingActions.hide());
      return true;
    })
    .catch((error) => {
      dispatch(configurationAction.error(error?.message || error));
      dispatch(loadingActions.hide());
      return false;
    });
};
