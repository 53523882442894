import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ringImg from 'assets/images/ring.jpg';
import diamondImg from 'assets/images/diamonds.jpg';
import watchImg from 'assets/images/watches.jpg';
import Box from 'components/UI/Box/Box';
import Button from 'components/UI/Button';
import { QUOTER_TYPE } from 'constants/services';
import { formatCurrency } from 'utils/formatCurrency';
import { ROUTE_PATH } from 'routes';
import { ZOHO_SCRIPT_TYPES } from 'constants/zohoScripts';
import { useEffect, useMemo, useState } from 'react';

const ResumeQuoter = (props) => {
  const {
    literals,
    type,
    form,
    onReset,
    quoterResponse,
  } = props;

  const navigate = useNavigate();
  const [disabled, setDisabled] = useState([QUOTER_TYPE.JEWELRY, QUOTER_TYPE.WATCHES].includes(type));

  useEffect(() => {
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  }, []);

  const zohoFormType = useMemo(() => {
    switch (type) {
      case QUOTER_TYPE.JEWELRY:
        return ZOHO_SCRIPT_TYPES.JEWELRY;
      case QUOTER_TYPE.WATCHES:
        return ZOHO_SCRIPT_TYPES.WATCHES;
      case QUOTER_TYPE.DIAMONDS:
        return ZOHO_SCRIPT_TYPES.DIAMONDS;
      default:
        return ZOHO_SCRIPT_TYPES.APPOINTMENT;
    }
  }, [type]);

  const renderHeader = () => {
    let image = null;
    let attributes = [];
    switch (type) {
      case QUOTER_TYPE.JEWELRY:
        image = ringImg;
        attributes = [
          { label: literals.jewelry.carats, value: `${form.material || '-'}` },
          { label: literals.jewelry.weight, value: `${form.weight || '-'}gr` },
        ];
        break;
      case QUOTER_TYPE.WATCHES:
        image = watchImg;
        attributes = [
          { label: literals.watches.brand, value: form.brand || '-' },
        ];
        break;
      case QUOTER_TYPE.DIAMONDS:
        image = diamondImg;
        attributes = [
          { label: literals.diamonds.weight, value: form.weight || '-' },
          { label: literals.diamonds.color, value: form.color?.name || '-' },
          { label: literals.diamonds.purity, value: form.purity || '-' },
          { label: literals.diamonds.diamondCut, value: form.cut || '-' },
        ];
        break;
      default:
        break;
    }
    if (!image) {
      return null;
    }

    return (
      <div className='row mt-3 mb-4'>
        <div className='col col-4'>
          <img src={image} alt='' />
        </div>
        <div className='col col-8'>
          <div className='d-flex  justify-content-center gap-3'>
            <div>
              {(attributes || []).map(({ label }, i) => (
                <p key={`attr-label-${i}`} className='h2 text-gray text-end'>{label}</p>
              ))}
            </div>
            <div>
              {(attributes || []).map(({ value }, i) => (
                <p key={`attr-value-${i}`} className='h2'>{value}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Box className='resumeQuoter-box mb-3'>
      {renderHeader()}
      <h1 className='mb-3'>{literals.probableLoanRange}</h1>
      <div className='table-resume-loan mb-4'>
        <table>
          <thead>
            <tr>
              <th width='30%'><p className='font-size-sm font-bold text-gray mb-1'>{literals.terms}</p></th>
              <th width='35%'><p className='font-size-sm font-bold text-gray mb-1'>{literals.maxLoan}</p></th>
              <th width='35%'><p className='font-size-sm font-bold text-gray mb-1'>{literals.minLoan}</p></th>
            </tr>
          </thead>
          <tbody>
            {(quoterResponse || []).map((quote, i) => (
              <tr key={`quote-${i}-term`}>
                <td><p className='font-size-sm font-bold text-gray mb-1'>{quote.Producto}</p></td>
                <td><p className='font-size-sm font-bold text-gray mb-1'>{formatCurrency(quote.PrestamoMaximo)}</p></td>
                <td><p className='font-size-sm font-bold text-gray mb-1'>{formatCurrency(quote.PrestamoMinimo)}</p></td>
              </tr>
            ))}
            {(quoterResponse?.length === 0 ? (
              <tr>
                <td colSpan={3}>
                  <p className='font-size-sm font-bold my-4 text-danger'>{literals.errorQuotes}</p>
                </td>
              </tr>
            ) : null)}
          </tbody>
        </table>
      </div>
      <div>
        <Button
          className='mb-3'
          type='secondary'
          text={literals.repeatQuote}
          onClick={onReset}
          disabled={disabled}
        />
        <Button
          type='primary'
          text={literals.createAppointment}
          onClick={() => { navigate(`${ROUTE_PATH.APPOINTMENT}?type=${zohoFormType}`, { replace: true }); }}
          disabled={disabled}
        />
      </div>
    </Box>
  );
};

ResumeQuoter.propTypes = {
  literals: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  onReset: PropTypes.func.isRequired,
  quoterResponse: PropTypes.array.isRequired,
};

export default ResumeQuoter;
