/* eslint-disable no-unused-vars */
import ApiFetch from 'utils/apiFetch';

const ApiFetchInstance = ApiFetch();

export const URLS = {
  createReference: '/payments/puntored/references',
  createMultipleReference: '/payments/puntored/references/batch',
  pdf: (externalOrder) => `/payments/puntored/references/${externalOrder}/pdf`,
  reference: (reference) => `/payments/puntored/references/${reference}`,
};
export default {
  async createReference(data) {
    const response = await ApiFetchInstance.post(URLS.createReference, data);
    return response;
  },
  async createMultipleReference(data) {
    const response = await ApiFetchInstance.post(URLS.createMultipleReference, data);
    return response;
  },
  async getPDF(externalOrder) {
    const response = await ApiFetchInstance.get(URLS.pdf(externalOrder), { responseType: 'blob' });
    return response;
  },
  async deleteReference(referenceId) {
    const response = await ApiFetchInstance.delete(URLS.reference(referenceId));
    return response;
  },
};
