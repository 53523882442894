import { CONTRACT, SECTION, PROVIDER_TYPE } from 'constants/global';
import Debugger from 'utils/debugger';
import PayCashApi from './repository';

export async function createReference(paymentKey, monto, amount, type, id, provider) {
  try {
    const params = {
      paymentKey: paymentKey || null,
      monto,
      amount,
      contractType: type,
      contract_id: type === CONTRACT ? id : null,
      section_id: type === SECTION ? id : null,
      os: window.getOS(),
    };

    let response;
    if (provider === PROVIDER_TYPE.PAYCASH) {
      response = await PayCashApi.createReference(params);
    } else {
      response = await PayCashApi.createReferencePuntored(params);
    }
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function createMultipleReference(totalAmount, orders, provider) {
  try {
    const params = {
      totalAmount,
      orders,
      os: window.getOS(),
    };

    let response;
    if (provider === PROVIDER_TYPE.PAYCASH) {
      response = await PayCashApi.createMultipleReference(params);
    } else {
      response = await PayCashApi.createMultipleReferencePuntored(params);
    }
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export function getPaycashPDF(externalOrder) {
  return PayCashApi.getPaycashPDF(externalOrder)
    .then((blob) => {
      window.share('paycash', blob, 'application/pdf');
    });
}

export async function deleteReference(referenceId, provider) {
  try {
    let response;
    if (provider === PROVIDER_TYPE.PAYCASH) {
      response = await PayCashApi.deleteReference(referenceId);
    } else {
      response = await PayCashApi.deleteReferencePuntored(referenceId);
    }
    return response;
  } catch (error) {
    Debugger.error(error);
    return false;
  }
}

export async function verifyHour() {
  try {
    const response = await PayCashApi.verifyHour();
    return response || true;
  } catch (error) {
    return false;
  }
}
