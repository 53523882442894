import moment from 'moment';

export const formatDate = (date, format = 'LL', utc = false) => {
  let dateInput = date;
  if (typeof date === 'string') {
    dateInput = new Date(date.endsWith('Z') ? date.slice(0, -1) : date);
  } else {
    dateInput = new Date(date);
  }
  const auxDate = utc ? moment.utc(dateInput) : moment(dateInput);
  return auxDate.format(format);
};
