import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate, Link, matchPath } from 'react-router-dom';
import useLiterals from 'utils/hooks/useLiterals';
import Translate from 'components/Translate';
import { ROUTE_PATH } from 'routes';
import { onLogout } from 'modules/session/actions';
import useConfiguration from 'hooks/useConfiguration';
import { CMS_MESSAGES } from 'constants/cms';
import useCmsPreview from 'hooks/useCmsPreview';
import { formatLink } from 'utils/formatLink';
import { EFFECT_LIST_BOX_RIGHT_IN_VIEW } from 'constants/effects';
import SETTINGS_SECTIONS from 'constants/settings';
import { motion } from 'framer-motion';
import Header from './components/Header';
import BottomMenu from './components/BottomMenu';
import './styles.scss';

const Layout = (props) => {
  const {
    children,
    title,
    onBack,
    className,
    bottomMenu,
    headerMenu,
    rightMenu,
  } = props;

  const { rightMenu: literalsRM } = useLiterals('layout');
  const configuration = useSelector((state) => state.configuration);
  const [openRightMenu, setOpenRightMenu] = useState(false);
  const { user, authenticated } = useSelector((state) => state.session);
  const { getConfigurationValue } = useConfiguration();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState([]);
  const { value: previewMessage } = useCmsPreview(CMS_MESSAGES.UPDATE_RIGHT_MENU);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (configuration.list.length) {
      setMenuItems(JSON.parse(getConfigurationValue('rightMenu') || '[]'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configuration]);

  useEffect(() => {
    if (previewMessage) {
      setMenuItems(previewMessage);
      setOpenRightMenu(true);
    }
  }, [previewMessage]);

  const handleCloseRightMenu = () => {
    if (openRightMenu) {
      setOpenRightMenu(false);
    }
  };

  const handleLogout = () => {
    dispatch(onLogout()).then(() => {
      setOpenRightMenu(false);
      navigate(ROUTE_PATH.LOGIN, { state: { from: 'logout' } });
    });
  };

  const isActualPath = (path) => {
    const match = matchPath({
      path,
      exact: true,
      strict: false,
    }, window.location.pathname);
    return match ? 'active' : '';
  };

  return (
    <div className={`layout ${className} ${!headerMenu ? 'no-header' : ''}`}>
      {headerMenu && (
        <Header
          title={title}
          onBack={onBack}
          rightMenu={rightMenu}
          openRightMenu={openRightMenu}
          onRightMenu={() => setOpenRightMenu(!openRightMenu)}
        />
      )}
      <div className={`layout-wrapper ${openRightMenu ? 'show-right-menu' : ''}`}>
        <motion.div
          initial={null}
          animate={openRightMenu ? 'open' : 'default'}
          variants={{
            open: {
              position: 'absolute',
              left: 0,
              width: '65%',
              top: 0,
              x: -120,
              zoom: 0.65,
              border: '20px solid white',
              padding: '0 20px 20px 20px',
              boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.2), 0 0 30px rgba(0, 0, 0, 0.2)',
              borderRadius: 30,
              height: '120vh',
              overflow: 'hidden',
            },
            default: {
              position: 'relative',
              width: '100%',
              x: 0,
            },
          }}
          className='layout-content'
          onClick={handleCloseRightMenu}
        >
          {children}
        </motion.div>
        {openRightMenu && (
          <div className={`layout-right-menu ${user?.id ? 'with-user' : ''}`}>
            {user?.id && (
              <div className='mb-3'>
                <p className='mb-1'><b>{user.firstName}</b></p>
                {user?.clientId && <Translate className='font-size-sm text-gray text-truncate' literal={literalsRM.client} vars={{ number: user.clientId }} />}
                {!user?.clientId && <div className='mt-3'><a href={ROUTE_PATH.setSettings(SETTINGS_SECTIONS.SAP_ASSOCIATE)} className='font-size-sm'>{literalsRM.associate}</a></div>}
              </div>
            )}
            <nav>
              <div className='links'>
                <motion.div key='nav-0' custom={0} {...EFFECT_LIST_BOX_RIGHT_IN_VIEW}>
                  <Link to={ROUTE_PATH.setSettings()} onClick={() => handleCloseRightMenu()} className={`text-decoration-none ${isActualPath(ROUTE_PATH.SETTINGS)}`}>
                    <Translate className='h4 text-gray mb-4' literal={literalsRM.settings} />
                  </Link>
                </motion.div>
                {authenticated && (
                  <>
                    <motion.div key='nav-1' custom={1} {...EFFECT_LIST_BOX_RIGHT_IN_VIEW}>
                      <Link to={ROUTE_PATH.PROFILE} onClick={() => handleCloseRightMenu()} className={`text-decoration-none ${isActualPath(ROUTE_PATH.PROFILE)}`}>
                        <Translate className='h4 text-gray mb-4' literal={literalsRM.profile} />
                      </Link>
                    </motion.div>
                    <motion.div key='nav-2' custom={2} {...EFFECT_LIST_BOX_RIGHT_IN_VIEW}>
                      <Link to={ROUTE_PATH.PAYMENTS} onClick={() => handleCloseRightMenu()} className={`text-decoration-none ${isActualPath(ROUTE_PATH.PAYMENTS)}`}>
                        <Translate className='h4 text-gray mb-4' literal={literalsRM.payments} />
                      </Link>
                    </motion.div>
                    <motion.div key='nav-3' custom={3} {...EFFECT_LIST_BOX_RIGHT_IN_VIEW}>
                      <Link to={ROUTE_PATH.RECEIPTS_HISTORY} onClick={() => handleCloseRightMenu()} className={`text-decoration-none ${isActualPath(ROUTE_PATH.RECEIPTS_HISTORY)}`}>
                        <Translate className='h4 text-gray mb-4' literal={literalsRM.reprintingReceipts} />
                      </Link>
                    </motion.div>
                  </>
                )}
                {menuItems.map((item, index) => {
                  const link = formatLink(item.url);
                  const i = index + 4;
                  return (
                    <motion.div key={`nav-${i}`} custom={i} {...EFFECT_LIST_BOX_RIGHT_IN_VIEW}>
                      <Link key={index} to={link.to} target={link.target} onClick={() => handleCloseRightMenu()} className={`text-decoration-none ${isActualPath(link.to)}`}>
                        <Translate className={`h4 text-gray mb-${index === menuItems.length - 1 ? '0' : '4'}`} literal={item.name} />
                      </Link>
                    </motion.div>
                  );
                })}
              </div>
              {authenticated && (
                <motion.div key='nav-logout' custom={2 + menuItems.length} {...EFFECT_LIST_BOX_RIGHT_IN_VIEW}>
                  <div className='h4 color-primary mt-4' onClick={handleLogout}>
                    {literalsRM.logout}
                  </div>
                </motion.div>
              )}
            </nav>
          </div>
        )}
      </div>
      {bottomMenu && <BottomMenu action={bottomMenu} />}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  onBack: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  title: PropTypes.string,
  className: PropTypes.string,
  headerMenu: PropTypes.bool,
  rightMenu: PropTypes.bool,
  bottomMenu: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

Layout.defaultProps = {
  title: '',
  onBack: null,
  className: '',
  headerMenu: true,
  rightMenu: true,
  bottomMenu: false,
};

export default Layout;
