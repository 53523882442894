export default {
  es: {
    auth: {
      clientNumber: 'Número de cliente',
      curp: 'CURP',
      accountNumber: 'No. de cuenta',
      EMAIL_VERIFICATION: 'email',
      SMS_VERIFICATION: 'sms',
      email: 'Correo electrónico',
      login: {
        hiAgain: '¡Hola de nuevo! {name}',
        connect: 'Conectarse',
        oneAccount: 'Una única cuenta para todos los servicios',
        clientNumber: 'Número de cliente',
        password: 'Contraseña',
        access: 'Acceder',
        forgotPassword: '¿Has olvidado la contraseña?',
        dontHaveAccount: '¿Aun no tienes cuenta? ¡Regístrate aquí!',
        createAccount: '¡Créala ahora!',
        accessBiometric: 'Acceder con biometría',
        accessWithUser: 'Acceder con usuario y contraseña',
        activateBiometric: '¿Quieres activar la biometría?',
        activateBiometricDescription: 'Los próximos accesos a la app serán mas rapidos y seguros',
        activateBiometricSuccess: 'Biometría activada con éxito, puedes cambiar esta preferencia desde ajustes en tu cuenta',
        activate: 'Activar',
        biometricError: 'Ha ocurrido un error al intentar acceder con biometría, por favor inicia sesión con tu usuario y contraseña',
        biometricUnavailable: 'La biometría no está disponible en el dispositivo',
        goToLogin: 'Ir a login',
      },
      signup: {
        signup: 'Registro',
        identifier: 'Identificador',
        ineOrClientNumber: 'Introduce tu INE o Número de cliente',
        personalData: 'Datos personales',
        contactData: 'Datos de contacto',
        authentication: 'Autenticación',
        // gender: 'Género',
        // male: 'Masculino',
        // female: 'Femenino',
        // other: 'Otro',
        // firstName: 'Nombre',
        // middleName: 'Segundo nombre',
        // paternalSurname: 'Apellido paterno',
        // maternalSurname: 'Apellido materno',
        confirmEmail: 'Confirmar correo electrónico',
        password: 'Contraseña',
        confirmPassword: 'Confirmar contraseña',
        validation: 'Validación',
        welcome: 'Bienvenido',
        validation1: '¡Descubre las ventajas de ser parte de la familia de Montepío Luz Saviñón! Asocia tu cuenta de cliente con email {email} y teléfono {phone} con nuestra conveniente APP ahora mismo y aprovecha al máximo nuestros servicios exclusivos.',
        validation2: '¡Haz que tu experiencia sea aún más fácil y segura! Selecciona un método para asociar tu cuenta con nuestra aplicación y recibirás un código temporal. ¡Introduce el código y comienza a disfrutar de todas las funcionalidades que te ofrecemos!',
        sendEmail: 'ENVIAR EMAIL',
        sendSms: 'ENVIAR SMS',
        introduceData: 'No hemos encontrado ningun cliente con esa información, introduce los siguientes datos para darte de alta',
        rfc: 'RFC',
        name: 'Nombre',
        paternalSurname: 'Apellido paterno',
        maternalSurname: 'Apellido materno',
        lastname: 'Apellido',
        birthDate: 'Fecha de nacimiento',
        gender: 'Género',
        male: 'Masculino',
        female: 'Femenino',
        other: 'Otro',
        rol: 'Rol',
        prospect: 'Prospecto',
        accredited: 'Acreditado',
        attorney: 'Apoderado',
        beneficialOwner: 'Dueño Beneficiario',
        insuranceBeneficiary: 'Beneficiario de Seguro',
        generalCommercial: 'Interl. comercial general',
        nationality: 'Nacionalidad',
        countryOfNationality: 'País de nacionalidad',
        address: 'Dirección',
        country: 'País',
        state: 'Estado',
        municipality: 'Municipio',
        colony: 'Colonia',
        street: 'Calle',
        exteriorNumber: 'Número exterior',
        postalCode: 'Código postal',
        cellNumber: 'Número celular',
        email: 'Correo electrónico',
        successTitle: 'REVISA TU EMAIL PARA FINALIZAR EL REGISTRO',
        successDescription: 'Hemos enviado un enlace a tu email para crear tu contraseña de acceso.',
        errorTitle: 'Ha ocurrido un error',
        errors: {
          USER_ALREADY_ASSOCIATED: 'El usuario ya está asociado a la app. Por favor, inicia sesión.',
          OTP_NOT_MATCH: 'El código introducido no coincide con el código enviado. Por favor, intenta de nuevo.',
          SERVICE_UNAVAILABLE: 'El servicio no está disponible en este momento. Por favor, intenta de nuevo más tarde.',
          INCOMPLETE_DATA: 'Tu usuario no tiene datos suficientes para darte de alta. Favor de acudir a la oficina más cercana para informarlos.',
          USER_EXISTS: 'El usuario ya está dado de alta en la aplicación. Por favor, inicia sesión.',
          USER_NOT_FOUND: 'No hemos encontrado ningun cliente de Montepio con esa información. Favor de acudir a una oficina para darte de alta.',
        },
        errorDescription: 'Ha ocurrido un error, por favor revisa los datos e intenta de nuevo',
      },
      recoverPassword: {
        restorePassword: 'Restablecer contraseña',
        enterFields: 'Selecciona si quieres buscar tu cuenta con tu email o teléfono, y te mandaremos un link para poder configurar tu contraseña de nuevo.',
        restore: 'Restablecer',
        successTitle: 'Solicitud realizada',
        successDescription: 'Si la cuenta existe en breve recibirás un enlace para que puedas restablecer tu contraseña.',
        errorDescription: 'Ha ocurrido un error, por favor revisa los datos e intenta de nuevo',
      },
      resetPassword: {
        title: 'Establecer nueva contraseña',
        createPassword: 'Crear Contraseña',
        newPassword: 'Confirmar Contraseña',
        errorDescription: 'Ha ocurrido un error, por favor revisa los datos e intenta de nuevo',
        successDescription: 'Tu contraseña ha sido creada con éxito',
        passwordRules: {
          length: 'Mínimo 8 caracteres y máximo 15',
          capitalLetter: 'Al menos una letra mayúscula',
          number: 'Al menos un número',
          specialCharacter: 'Al menos un carácter especial (@,#,$,%..)',
          equal: 'Las contraseñas deben ser iguales',
        },
      },
      otpModal: {
        title: 'Asocia tu cuenta',
        info: 'Introduce el código de {length} caracteres que has recibido por {type} para continuar con el registro.',
        introduceCode: 'Introduce el código',
        codeNotReceived: '',
      },
    },
    sessionClosed: {
      title: 'Sesión terminada',
      description: 'Tu sesión ha caducado, por favor inicia sesión de nuevo',
      goToLogin: 'Ir al login',
    },
    layout: {
      rightMenu: {
        client: 'Cliente: {number}',
        settings: 'Ajustes',
        profile: 'Mi perfil',
        documents: 'Documentos',
        discoverApp: 'Descubre la app',
        modifyPassword: 'Modificar\ncontraseña',
        onlinePayment: 'Pago en línea',
        who: 'Quiénes somos',
        privacyPolicy: 'Política de\nprivacidad',
        logout: 'Cerrar sesión',
        finsus: 'Finsus',
        appointment: 'Cita',
        associate: 'Asociar cliente',
        payments: 'Pago en linea',
        reprintingReceipts: 'Reimpresión de comprobantes',
      },
    },
    bottomMenu: {
      home: 'Home',
      billing: 'Facturación',
      stores: 'Tiendas',
      help: 'Asistencia',
    },
    home: {
      welcome: '¡Bienvenido',
      logout: 'Cerrar sesión',
      registerOrAccess: 'Registro / Acceso',
      guestUser: 'Usuario invitado',
      call: 'Llamar',
      schedule: 'Agendar una cita',
      usernearbyShops: 'Sucursales cercanas a tu ubicación:',
      nearbyShops: 'Sucursales cercanas:',
      shopNear1: '¡Tienes una',
      shopNear2: 'sucursal MLS',
      shopNear3: 'cerca de ti!',
      details: 'Detalle',
      yourContractsAndSections: 'Tus contratos y apartados',
      storesNotFound: 'Vaya, parece que no hay tiendas cerca de ti',
      goToStores: 'Ir al buscador',
      yourContracts: 'Tus contratos',
      yourSections: 'Tus apartados',
      numActiveContracts: 'Tienes activos:',
      showAll: 'ver todo',
      contracts: 'Contratos',
      sections: 'Apartados',
      loans: {
        title: 'Préstamos',
        viewAll: 'ver todo',
        loanWarranty: 'Préstamo con garantía de auto',
        driveDreams: '¡Conduce hacia tus sueños!',
        simulateLoan: 'Simula tu préstamo',
        transformJewelry: 'Transforma tus joyas en oportunidades',
        offers: 'Ofertas de confianza',
        quoteJewelry: 'Cotizas tu joyas',
        executiveDriver: 'Conductor ejecutivo',
        uberDriver: '¿Ya eres conductor de UBER?',
        offerForYou: '¡Tenemos una oferta para ti!',
        info: 'Infórmate aquí',
      },
      shopping: {
        title: 'Compras',
        viewAll: 'ver todo',
        onlineStore: 'Tienda en línea',
        smartShopping: 'Compras inteligentes, Satisfacción Total',
        visitStore: 'Visita la tienda',
        auctions: 'Subastas',
        excitingBids: 'Pujas emocionantes, Precios Increíbles',
        discoverYourAuctions: 'Descubre tus subastas',
        auction: 'Subasta',
        online: 'Online',
        register: 'Registrarse para ofertar',
        catalog: 'Catálogo',
      },
      oportunities: {
        title: 'Oportunidades',
        viewAll: 'ver todo',
        oneAccount: 'Una cuenta para todo',
        payOnline: 'Ingresa y paga tus plazos 100% online',
        openMyAccount: 'Abre mi cuenta',
      },
    },
    automaticQuoter: {
      title: 'Cotizador automático',
      subtitle: 'Aquí podrás hacerle una fotografía al objeto que quieras empeñar para comenzar el proceso de tasación. Los objetos que puedes empeñar incluyen:',
      cars: 'Auto, toma una foto del frontal.',
      watches: 'Relojes.',
      jewelry: 'Alhajas.',
      diamonds: 'Diamantes.',
      camera: 'Cámara',
      takePhoto: 'Toma una foto del producto que quieres cotizar',
      takePhotoCars: 'Toma una foto del auto que quieres cotizar',
      takePhotoDiamonds: 'Toma una foto del diamante que quieres cotizar',
      takePhotoWatches: 'Toma una foto del reloj que quieres cotizar',
      takePhotoJewelry: 'Toma una foto de la joya que quieres cotizar',
      cameraPermissionDenied: 'No has permitido el acceso a la cámara, por favor, permite el acceso para continuar',
      cameraPermissionPending: 'Esperando permisos para acceder a la cámara',
      errorIdentifyingPhoto: 'No hemos detectado autos, alhajas, diamantes o relojes.\n\n Puedes volver a intentarlo.',
      vehucleIdentified: 'Vehículo identificado',
      watchIdentified: 'Reloj identificado',
      jewelryIdentified: 'Joya identificada',
      diamondIdentified: 'Diamante identificado',
      brand: 'Marca',
      model: 'Modelo',
      version: 'Versión',
      year: 'Año',
      scanningImage: 'Escaneando imagen, esta operación puede tardar hasta un minuto',
      otherIdentified: '¡OTHER_SUBTYPE identificado!',
      otherIdentifiedSubtype: 'Hemos identicado que quieres empeñar un objeto de tipo <strong>"OTHER_SUBTYPE"</strong>',
      otherIdentifiedBrand: ' de la marca <strong>OTHER_BRAND</strong>',
      otherIdentifiedModel: ' y modelo <strong>OTHER_MODEL</strong>',
      otherIdentifiedInfo: 'Actualmente no contamos con un cotizador para este tipo de producto, pero puedes acudir a una de nuestras sucursales para que nuestros expertos te ayuden a cotizarlo.',
      searchStore: 'Buscar tienda',
      infoStorageImages: 'El uso de la cámara es únicamente para el escaneo de productos que son susceptibles de empeño. La app no guardará ninguna imagen capturada por la camara.',
    },
    quoter: {
      title: '¿Prefieres cotizar otra cosa?',
      loanAmount: 'Monto del préstamo',
      createAppointment: 'Agendar cita',
      contactForm: 'Formulario de contacto',
      probableLoanRange: 'Rango probable de préstamo',
      terms: 'Plazos',
      maxLoan: 'Préstamo máx.',
      minLoan: 'Préstamo mín.',
      repeatQuote: 'Volver a cotizar',
      threeMonths: '3 meses',
      sixMonths: '6 meses',
      monthly: 'Mensual',
      errorTitle: 'Ha ocurrido un error',
      errorQuotes: 'No hemos podido realizar la cotización, por favor revisa los datos e intentalo de nuevo',
      errorDescription: 'No hemos podido realizar la cotización, por favor revisa los datos e intentalo de nuevo',
      jewelry: {
        title: 'Cotizador de alhajas',
        info: 'Selecciona el kilataje de su prenda e ingrese el peso aproximado del metal:',
        carats: 'Kilataje',
        weightInGrams: 'Peso en gramos',
        weight: 'Peso',
        getQuote: 'COTIZAR ALHAJA',
      },
      cars: {
        title: 'Cotizador de autos',
        info: 'Selecciona las características del auto:',
        year: 'Año',
        brand: 'Marca',
        model: 'Modelo',
        version: 'Versión',
        product: 'Producto',
        getQuote: 'COTIZAR AUTO',
        saveCar: 'Me lo guardan',
        takeCar: 'Me lo llevo',
        information: 'Información',
        store: 'Sucursal',
        quoter: 'Cotizador',
        loanInfo: 'Obtén tu <strong>préstamo inmediato</strong> en Montepío Luz Saviñón, con las condiciones más ventajosas del mercado.',
        loanBenefit1: '<strong>Síguelo manejando</strong> o déjalo en resguardo.',
        loanBenefit2: 'Monto de préstamo de <strong>hasta el 80%</strong> por la factura de tu auto.',
        loanBenefit3: 'Cómodos plazos.',
        loanBenefit4: 'Solo <strong>paga el tiempo que ocupes.</strong>',
        loanBenefit5: 'Puedes <strong>abonar a capital</strong> sin pagar intereses adicionales o de atraso.',
        biweeklypayment: 'Pago quincenal',
        monthlyTerm: 'Plazo mensual',
        minAmount: 'El monto mínimo es de {amount}',
        maxAmount: 'El monto máximo es de {amount}',
      },
      watches: {
        title: 'Cotizador de relojes',
        info: 'Selecciona las características del reloj:',
        brand: 'Marca',
        model: 'Modelo',
        averagePrice: 'Precio promedio de su reloj',
        status: 'Estado',
        statusList: {
          new: 'Como nuevo',
          good: 'Buen estado',
          used: 'Bastante usado',
        },
        getQuote: 'COTIZAR RELOJ',
      },
      diamonds: {
        title: 'Cotizador de diamantes',
        info: 'Selecciona las características del diamante:',
        weightInGrams: 'Peso en gramos',
        weightInCarats: 'Peso en kilates',
        amount: 'Cantidad',
        getQuote: 'COTIZAR DIAMANTE',
        weight: 'Peso',
        diamondColor: 'Color del diamante',
        color: 'Color',
        purity: 'Pureza',
        diamondCut: 'Talla',
        cuts: {
          brilliant: 'Brillante',
          esmerald: 'Esmeralda',
          pear: 'Pera',
          marquise: 'Marquesa',
          heart: 'Corazón',
          asscher: 'Asscher',
          oval: 'Oval',
          radiant: 'Radiante',
          princess: 'Princesa',
        },
      },
    },
    quotes: {
      cars: {
        title: 'Cotizador de Autos',
        description: 'Encuentra los mejores productos y págalos en cómodos plazos sin intereses',
      },
      diamonds: {
        title: 'Cotizador de Diamantes',
        description: 'Encuentra los mejores productos y págalos en cómodos plazos sin intereses',
      },
      watches: {
        title: 'Cotizador de Relojes',
        description: 'Encuentra los mejores productos y págalos en cómodos plazos sin intereses',
      },
      executiveDriver: {
        title: 'Conductor Ejecutivo',
        description: 'Encuentra los mejores productos y págalos en cómodos plazos sin intereses',
      },
      jewelry: {
        title: 'Cotizador de Alhajas',
        description: 'Encuentra los mejores productos y págalos en cómodos plazos sin intereses',
      },
    },
    services: {
      title: 'Todas las opciones',
      loans: 'Préstamos',
      shopping: 'Compras',
      opportinities: 'Oportunidades',
    },
    billing: {
      title: 'Facturación',
      billingPortal: 'Portal de facturación',
      fillInputs: 'Ingresa los datos solicitados',
      contractPayment: 'Pago de contrato',
      sellNote: 'Nota de venta',
      contractSection: 'Contrato de apartado',
      operationType: 'Tipo de operación',
      folioNumber: 'Folio de venta',
      operationDate: 'Fecha de operación',
      amount: 'Monto',
      officeNumber: 'Número de sucursal',
    },
    onboarding: {
      imClient: 'Ya soy cliente',
      slide1: {
        title: 'Valor Real,\nSoluciones Instantáneas',
        description: 'Transformamos tus bienes en oportunidades financieras inmediatas, con la confianza y seguridad que mereces.',
      },
      slide2: {
        title: 'Compras Inteligentes,\nSatisfacción Total',
        description: 'Explora un mundo de productos al alcance de tu mano, con la comodidad y seguridad de una compra online perfecta.',
      },
      slide3: {
        title: 'Pujas emocionantes,\n Precios Increíbles',
        description: 'Sumérgete en el emocionante mundo de las subastas, donde cada puja puede ser el camino a un gran hallazgo.',
      },
      welcome: '¡Bienvenid@!',
      startJourney: 'Comienza tu viaje hacia soluciones financieras inteligentes y personalizadas con nosotros',
      driveDreams: 'Conduce hacia tus sueños',
      loanWarranty: 'Préstamo con garantía de auto',
      simulateLoan: 'Simula tu préstamo',
      goldTime: 'Momentos de Oro',
      transformJewelry: 'Transformas tus joyas en Oportunidades',
      quoteJewelry: 'Cotiza tu joyas',
      watchPromise: 'Tu Reloj, Nuestra Promesa',
      evaluateWatch: 'Experimenta la tranquilidad de obtener el mejor valor por tu reloj',
      quoteWatch: 'Cotiza tu reloj',
      beAClient: 'Hacerme cliente',
    },
    settings: {
      language: 'Idioma',
      spanish: 'Español',
      english: 'Inglés',
      notifications: 'Notificaciones',
      biometricAccess: 'Acceso biométricos',
      enabled: 'Activado',
      disabled: 'Desactivado',
      faceIdAccess: 'Acceso con biometría',
      faceIdEnabled: 'Biometría Activada',
      faceIdDisabled: 'Biometría Desactivado',
      faceIDSuccess: 'Acceso con Biometría activado con éxito',
      faceIDError: 'Ha ocurrido un error al activar la biometria, intentelo de nuevo.',
      currentlyRegisteredFaceId: 'Actualmente tienes registrado el Face ID',
      disableFaceId: 'DESACTIVAR BIOMETRÍA',
      enableFaceId: 'ACTIVAR BIOMETRÍA',
      unavailable: 'No disponible',
      deleteFaceId: 'Borrar Face ID',
      modifyMlsPassword: 'Modificar contraseña MLS',
      modifyPassword: 'Modificar contraseña',
      enterFollowingData: 'Introduce los siguientes datos',
      currentPassword: 'Contraseña actual',
      newPassword: 'Nueva Contraseña',
      confirmPassword: 'Repite la nueva contraseña',
      confirm: 'CONFIRMAR',
      passwordSaved: 'Tu contraseña ha sido guardada con éxito',
      wrongPassword: 'La contraseña actual es incorrecta',
      genericError: 'Ha ocurrido un error, por favor intenta de nuevo',
      appVersion: 'Versión app',
      version: 'Versión',
      sapAssociate: 'Asociar con cliente de Montepío',
      notAssociated: 'No asociado',
      queryParams: 'Parámetros de la URL',
      operatingSystemVersion: 'Versión del sistema operativo',
      clientNumber: 'Número de cliente',
      inputClientNumber: 'Introduce el número de cliente que quieres asociar con esta cuenta',
      sap: {
        successTitle: 'Usuario asociado correctamente',
        successDescription: '',
        errorTitle: 'No se ha podido asociar el usuairo',
        errorDescription: 'Compruebe el número de 4 dígitos e inténtelo de nuevo',
        unlinkSAP: 'Desvincular cliente Montepío',
        unlinkDescription: '¿Estás seguro de que deseas desvincular tu cuenta de cliente con la app?',
        unlinkSuccessTitle: 'Desvinculación exitosa',
        unlinkSuccessDescription: 'Tu cuenta de cliente ha sido desvinculada con éxito',
        unlinkErrorTitle: 'Error al desvincular',
        unlinkErrorDescription: 'Ha ocurrido un error al desvincular tu cuenta de cliente con la app, por favor intenta de nuevo',
      },
      pushNotifications: 'Notificaciones push',
      disableNotifications: 'Desactivar notificaciones',
      enableNotifications: 'Activar notificaciones',
      notificationsNotAvailable: 'Las notificaciones no están disponibles, revisa si has bloqueado las notificaciones en los ajustes del dispositivo',
      notificationsOtherUser: 'Para poder desactivar las notificaciones debes iniciar sesion con el usuario que las activo',
    },
    stores: {
      title: 'Sucursales y Tiendas',
      search: 'Buscar sucursales y tiendas',
      addressSearch: 'Dirección, Sucursal o C.P.',
      shop: 'Sucursal',
      shopAddress: 'Dirección de sucursal',
      contactNumber: 'Número de contacto',
      timetable: 'Horario',
      scheduleTime: 'L-V {weekly}\nS {weekend}',
      yoCan: 'Podrás empeñar',
      call: 'Llamar',
      scheduleAppointment: 'Agendar una cita',
      appointmentSuccess: 'Cita exitosa',
      appointmentDate: 'Fecha de la cita',
      schedule: 'Agendar',
      selectOther: 'Seleccionar otra sucursal',
      searchOther: 'Buscar otra sucursal',
    },
    documents: {
      contracts: 'Contratos',
      accountStatements: 'Estados de cuenta',
    },
    payments: {
      title: 'Pago en línea',
      reprintingReceipts: 'Reimpresión de comprobantes',
      contracts: 'Contratos',
      sections: 'Apartados',
      contractOrSection: 'Contrato/Apartado',
      upcomingExpirations: 'Próximos vencimientos',
      contract: 'Contrato',
      nextExpiration: 'Vencimiento:',
      pay: 'Realizar pago',
      multiplePayment: 'Pago múltiple',
      noContracts: 'No tienes contratos',
      noSections: 'No tienes apartados',
      sectionProducts: '({number} productos)',
      loadingRedirect: 'Un momento...',
      redirectError: 'Se ha producido un error, cierra esta ventana y vuelve a la app para intentarlo de nuevo',
      closeWindow: 'Cierra esta ventana y vuelve a la app',
    },
    downloadApp: {
      title: '¡DESCÁRGATE LA APP DE MONTEPÍO!',
      available: 'Ya disponible en:',
    },
    paymentGateway: {
      title: 'Pasarela de pago',
      shippingAddressStep: {
        title: 'Tienda onlie',
        address: 'Dirección de envío',
        continue: 'Siguiente',
        form: [
          { label: 'Nombre', value: 'name', required: true },
          { label: 'Apellidos', value: 'lastName', required: true },
          { label: 'Correo electrónico', value: 'email', required: true },
          { label: 'Número celular', value: 'phone', required: true },
          { label: 'C.P.', value: 'cp', required: true },
          { label: 'País', value: 'country', required: true },
          { label: 'Estado', value: 'state', required: true },
          { label: 'Municipio', value: 'municipality', required: true },
          { label: 'Colonia', value: 'colony', required: true },
          { label: 'Calle', value: 'street', required: true },
          { label: 'Número', value: 'exteriorNumber', required: true },
        ],
      },
      paymentMethodStep: {
        title: 'Tienda onlie',
        paymentMethod: 'Forma de pago',
        subtitle: '¿Cómo quieres pagar?',
        payWithCard: 'Pagar con tarjeta',
      },
      confirmationStep: {
        title: 'Confirmación',
      },
    },
    profile: {
      myProfile: 'Mi perfil',
      alias: 'Alias',
      phone: 'Celular',
      address: 'Dirección',
      email: 'Email',
      ine: 'INE',
      errorUploadingImage: 'Ha ocurrido un error al subir la imagen, el tamaño máximo son 10MB, revisa la imagen e intentalo de nuevo',
      noLibraryPermissions: 'No has dado permisos para acceder a las imagenes del dispositivo, revisa los permisos desde los ajustes del dispositivo e intentalo de nuevo',
      confirmChange: 'Confirma el cambio',
      introduceCode: 'Introduce el código de confirmación que te hemos enviado para poder continuar',
      invalidOtp: 'El código de confirmación es inválido',
      successSaved: 'Cambios guardados correctamente',
      editProfileInfo: 'Recuerda que solo se puede hacer una modificación al día de tus datos.',
      editProfile: 'Editar perfil',
      selectOption: 'Solo puedes realizar una modificación de uno de tus datos al día, por favor selecciona el que deseas modificar.',
      currentEmail: 'Email actual',
      newEmail: 'Nuevo email',
      currentPhone: 'Celular actual',
      newPhone: 'Nuevo celular',
      changePhone: 'Cambiar celular',
      changeEmail: 'Cambiar email',
      confirmEmailOTP: 'Te hemos enviado un código de confirmación a tu email',
      confirmSMSOTP: 'Te hemos enviado un código de confirmación por SMS a tu celular',
      confirmInfoBoth: 'Para poder realizar el cambio, te hemos enviado un código de confirmación a tu email y celular, estos codigos son independientes, por lo que pueden ser diferentes.',
      confirmInfoEmail: 'Para poder realizar el cambio, te hemos enviado un código de confirmación a tu email.',
      confirmInfoPhone: 'Para poder realizar el cambio, te hemos enviado un código de confirmación por SMS a tu celular.',
      emailOTP: 'Código de confirmación de email',
      phoneOTP: 'Código de confirmación de celular',
      lastEditTime: 'No puedes volver a editar tus datos hasta {date}',
      EMAIL_ALREADY_EXISTS: 'El correo ingresado ya se encuentra registrado',
      PHONE_ALREADY_EXISTS: 'El teléfono ingresado ya se encuentra registrado',
      ERROR_CREATING_EMAIL_OTP: 'Ocurrió un error al generar OTP',
      ERROR_CREATING_SMS_OTP: 'Ocurrió un error al generar OTP',
      ERROR_SENDING_EMAIL: 'Ocurrió un error al enviar OTP',
      ERROR_SENDING_SMS: 'Ocurrió un error al enviar OTP',
      ERROR_UPDATING_CLIENT: 'Ocurrió un error al actualizar el cliente',
      UPDATE_SAP_CLIENT_ERROR: 'Ocurrió un error al actualizar el cliente en SAP',
      INVALID_OTP_EMAIL: 'El código de confirmación del email es inválido',
      INVALID_OTP_PHONE: 'El código de confirmación del celular es inválido',
    },
    executiveDriver: {
      title: 'Conductor ejecutivo',
      subtitle: 'Financia tu nuevo auto UBER',
      info1: '¿Ya eres conductor Uber? ¡Tenemos una oferta especial para ti en Montepío Luz Saviñón!',
      info2: 'Obtén un financiamiento exclusivo para adquirir tu propio auto con un plan de pagos fijos y sin enganche.',
      call: 'Llamar',
      weContactYou: 'Te contactamos',
      benefits: {
        title: 'Beneficios',
        gas: 'Bono gratuito de gasolina por apertura',
        noPayments: 'Sin enganche ni pagos residuales',
        coverageInsurance: 'Seguro de cobertura amplia',
        paymentCapacity: 'Adapta los plazos tu capacidad de pago',
        interestRate: 'Tasa de interés más baja que en una agencia',
        cars: 'Contamos con autos nuevos y seminuevos',
      },
      requirements: {
        title: 'Requisitos',
        age: 'Edad de 27 a 63 años',
        threeMonths: '3 meses de antigüedad vigente y continua en la plataforma',
        trips: '700 viajes realizados en esos 3 meses',
        rating: 'Calificación 4.7 estrellas',
        billing: 'Contar con las últimas 12 a 16 capturas de pantalla de facturación',
        location: 'Aplica en CDMX, Área Metropolitana y Estado de México',
      },
    },
    contract: {
      myDeadlines: 'Mis plazos',
      contract: 'Contrato',
      section: 'Apartado',
      returned: 'Abonado',
      pending: 'Restante',
      total: 'Total',
      pendingCapital: 'Capital restante',
      nextExpiration: 'Fecha de vencimiento:',
      amortize: 'Aplicar pago',
      paymentsHistory: 'Historial de pagos',
      payments: 'Historial de pagos',
      pendingPayments: 'Pagos pendientes',
      CANCELED: 'Cancelado',
      PAID: 'Pagado',
      PENDING: 'Pendiente',
      FAILED: 'Fallido',
      PENDING_SAP: 'Pendiente SAP',
      status: 'Estatus',
      graceExpiration: 'Fecha de gracia',
      errorDownloadNote: 'Ha ocurrido un error al descargar la nota de pago, intentalo de nuevo más tarde.',
      downloadReceipt: 'Descargar comprobante',
      contract03NotExtended: 'Recuerda que este modelo de contratos no extiende calendario, por lo que te sugerimos realizar la liquidación o re empeño de tu prenda en sucursal.',
    },
    contractPay: {
      amortize: 'Opciones de pago',
      operationType: 'Tipo de operación',
      limitDate: 'Fecha límite de pago',
      minAmountRule: 'El monto mínimo de pago es de {amount}',
      amount: 'Monto del pago',
      pay: 'Realizar pago',
      paymentMethod: '¿Cómo quieres pagar?',
      maxAmountPaycash: 'Máximo {amount}',
      payWithCard: 'Pagar con tarjeta',
      paymetResume: 'Resumen del pago',
      returnToApp: 'Ya puedes cerrar esta ventana y volver a la app',
      contracts: 'Contratos',
      contract: 'Contrato',
      section: 'Apartado',
      date: 'Fecha',
      card: 'Tarjeta',
      referencePayment: 'Pago Referenciado',
      paymentMethod2: 'Forma de pago',
      downloadNote: 'Descargar nota de pago',
      status: 'Estatus',
      totalAmount: 'Total a pagar',
      totalAmountPaid: 'Total pagado',
      noPayments: 'No tienes pagos',
      onlyOnePayment: 'Un pago al día',
      noPayment: 'No pagar',
      pendingCapital: 'Capital restante',
      office: 'Sucursal',
      numContracts: 'Número de contratos',
      multiplePayment: 'Pago múltiple',
      multipleResume: 'Has seleccionado {contracts} contratos con un valor total de {amount}',
      paymentNotFound: 'No se ha encontrado el pago',
      failureLoadingReference: 'Error al cargar referencia',
      failureLoadingReferenceDescription: 'Ha ocurrido un error al cargar la referencia de pago, revisa el estado del pago en los detalles del contrato',
      statusValues: {
        // COMPLETED, PENDING, CANCELED, PAYMENT_SAP_PENDING, FAILED
        PENDING: 'Pendiente',
        COMPLETED: 'Completado',
        CANCELED: 'Cancelado',
        PAYMENT_SAP_PENDING: 'Pendiente SAP',
        FAILED: 'Fallido',
      },
      bancomer: {
        confirmTitle: 'Confirmación de pago',
        confirmDescription: '¿Estás seguro de que deseas realizar el pago? Se te redirigirá a la pasarela de pago de BBVA para completar la transacción.',
        confirmDescriptionExternalRedirect: '*Se abrirá el navegador para completar la transacción.',
        successTitle: 'Pago exitoso',
        failureTitle: 'Error al realizar el pago',
        failureReferenceTitle: 'Error al crear referencia',
        failureReferenceDescription: 'Ha ocurrido un error al crear la referencia de pago, por favor intenta de nuevo',
      },
      reference: {
        successContractTitle: 'SE HA GENERADO UNA ORDEN DE PAGO PARA EL CONTRATO Nº {id}',
        successSectionTitle: 'SE HA GENERADO UNA ORDEN DE PAGO PARA EL APARTADO Nº {id}',
        successMultipleTitle: 'SE HA GENERADO UNA ORDEN DE PAGO MÚLTIPLE',
        successDescription: 'En breve recibirás un email de confirmación con los siguientes datos para realizar el pago:',
        reference: 'Referencia',
        contractNumber: 'Número de contrato',
        sectionNumber: 'Número de apartado',
        issueDate: 'Fecha de emisión',
        amount: 'Monto del pago',
        failureTitle: 'Error al crear referencia',
        failureDescription: 'Ha ocurrido un error al crear la referencia de pago, por favor intenta de nuevo',
        generatePaymentOrder: 'Generar orden de pago',
        amountError: 'El monto seleccionado debe ser igual a {amount} o estar entre {min} y {max}',
        deleteReference: 'Eliminar referencia',
        deleteReferenceDescription: '¿Estás seguro de que deseas eliminar la referencia de pago?',
        downloadNote: 'Descargar nota de pago',
        numberOfContracts: 'Número de contratos',
      },
    },
    appointment: {
      successTitle: 'Cita registrada correctamente',
      notificationTitle: 'Cita agendada',
      notificationBody: 'Tu cita ha sido agendada con éxito',
      office: 'Sucursal',
      address: 'Dirección',
      date: 'Fecha',
      selectOffice: 'Selecciona una sucursal',
      scripts: {
        First_Name: 'Nombre*',
        Last_Name: 'Apellido*',
        reset: 'Restablecer',
        submit: 'Enviar',
      },
    },
    pageError: {
      title500: 'Lo sentimos, ha ocurrido un error inesperado',
      description500: 'Nuestro equipo ha sido informado del problema. Pedimos disculpas por las molestias.',
      title404: 'Lo sentimos, la página que buscas no existe',
      title401: 'Lo sentimos, no estás autorizado para ver esta página',
      title400: 'Lo sentimos, ha ocurrido un error en la solicitud',
      unknownStatus: 'The status returned is unknown and has not been handled',
      back: 'Volver al inicio',
    },
    notFound: {
      title: '¡Vaya!',
      description: 'No encontramos lo que buscas',
      backToHome: 'Volver a la página principal',
    },
    errors: {
      error: 'Error',
      unknown: 'Ha ocurrido un error desconocido',
      400: 'La solicitud no es válida',
      401: 'No estás autorizado para realizar esta acción',
      404: 'No se encuentra el recurso solicitado',
      500: 'Ha ocurrido un error inesperado',
      unknownUserOrPassword: 'Usuario o contraseña incorrectos',
      fileNotFound: 'Archivo no encontrado',
      default: 'Ha ocurrido un error, por favor revisa los datos e intenta de nuevo',
      ERROR_SENDING_EMAIL: 'Se ha producido un error al enviar el email',
      ERROR_SENDING_SMS: 'Se ha producido un error al enviar el SMS',
      ERROR_CREATING_EMAIL_OTP: 'Se ha producido un error al generar el código de confirmación',
      ERROR_CREATING_PHONE_OTP: 'Se ha producido un error al generar el código de confirmación SMS',
    },
    select: {
      selectOneOption: 'Selecciona una opción',
    },
    maintenance: {
      title: 'Mantenimiento',
      description: 'Estamos realizando mejoras en nuestra aplicación, por favor intentalo de nuevo más tarde',
    },
    updateApp: {
      title: 'Nueva versión',
      description: 'Es necesario que actualices la aplicación para continuar usandola',
    },
    zohoForm: {
      First_Name: 'Nombre*',
      Last_Name: 'Apellido*',
      reset: 'Restablecer',
      submit: 'Enviar',
      formSent: 'Formulario enviado',
      formSentError: 'Ha ocurrido un error al enviar el formulario, por favor intentalo de nuevo',
      mandatoryField: 'El campo "{field}" es obligatorio',
      invalidEmail: 'El email introducido en el campo "{field}" no es válido',
      invalidPhone: 'El teléfono introducido en el campo "{field}" no es válido, debe tener 10 carácteres numéricos',
    },
    cmsPopups: {
      contractExpiration: {
        CONTRACT: '* Contrato: <b>{id}</b> con vencimiento al <b>{date}</b>',
        SECTION: '* Apartado: <b>{id}</b> con vencimiento al <b>{date}</b>',
      },
    },
    gestopago: {
      error: 'Ha ocurrido un error al cargar la página, por favor intentalo de nuevo más tarde',
    },
    common: {
      loading: 'Cargando...',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      yes: 'Sí',
      no: 'No',
      error: 'Error',
      success: 'Éxito',
      warning: 'Advertencia',
      info: 'Información',
      close: 'Cerrar',
      accept: 'Aceptar',
      reject: 'Rechazar',
      save: 'Guardar',
      edit: 'Editar',
      delete: 'Eliminar',
      add: 'Añadir',
      search: 'Buscar',
      searching: 'Buscando...',
      filter: 'Filtrar',
      clear: 'Limpiar',
      next: 'Siguiente',
      previous: 'Anterior',
      back: 'Volver',
      finish: 'Finalizar',
      send: 'Enviar',
      continue: 'Continuar',
      favorite: 'Favorito',
      goBack: 'Volver',
      retry: 'Reintentar',
      ine: 'INE',
      clientNumber: 'Número de cliente',
      phone: 'Número celular',
      landline: 'Número fijo',
      product: 'Producto',
      name: 'Nombre',
      lastName: 'Apellido',
      email: 'Correo electrónico',
      state: 'Estado',
      reset: 'Restablecer',
      telephone: 'Teléfono',
      page: 'Página',
      download: 'Descargar',
      viewAll: 'Ver todos',
      contract: 'Contrato',
      section: 'Apartado',
      showDetails: 'Mostrar detalles',
      passwordRequirements: 'La contraseña debe tener al menos 10 caracteres y no puedes repetir las anteriores',
      genericError: 'Ha ocurrido un error, por favor intenta de nuevo',
      outOfHour: 'Lo sentimos, esta opción no está disponible en este momento ({now} Ciudad de México). El horario para poder realizar pagos es el siguiente:',
      amountRangeErrorWithOriginal: 'El monto seleccionado debe ser igual a {amount} o estar entre {min} y {max}',
      amountRangeError: 'El monto seleccionado debe estar entre {min} y {max}',
      days: {
        0: 'Lunes',
        1: 'Martes',
        2: 'Miércoles',
        3: 'Jueves',
        4: 'Viernes',
        5: 'Sábado',
        6: 'Domingo',
      },
    },
  },
};
